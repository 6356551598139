import React, { useEffect,useState,forwardRef,useImperativeHandle } from 'react'
import './index.less'
import {NoData} from '@components'
import {getSubordinateRechargeRecord} from '@api'
import { Pagination } from 'antd-mobile'

function Index(props,ref) {
    // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        setCurPage
    }))
    // 存储报表数据
    const [rechargeRecordData, setRechargeRecordData] = useState({})
    // 是否显示无数据结构
    const [showNoData, setShowNoData] = useState(true)
    // 请求数据的页码
    const [curPage, setCurPage] = useState(1)
    useEffect(()=>{
        getSubordinateRechargeRecord({...props.subordinateRechargeRecordParams,username:props.username,status:props.status,pageSize:10,page:curPage}).then(res=>{
            React.$windowScrollTop()
            if(res.result===200000) {
                setRechargeRecordData(res.data)
                if(!res.data.list.length) {
                    setShowNoData(true)
                } else {
                    setShowNoData(false)
                }
            }
        })
    },[props.subordinateRechargeRecordParams,curPage]) // eslint-disable-line react-hooks/exhaustive-deps
    // 充值方式判断
    function typeFilter(type) {
        let statusList = ''
        if (type === 1) {
            statusList = '线下'
        } else if (type === 2) {
            statusList = '线上'
        } else if (type === 3) {
            statusList = '后台人工充值'
        }
        return statusList
    }
    return (
        <div className="subordinate-recharge-record-wrap">
            {!showNoData&&<Pagination className="subordinate-recharge-record-pagination" total={rechargeRecordData.pageCount} current={curPage} onChange={v=>setCurPage(v)} />}
            {!showNoData&&rechargeRecordData.list.map((item,index)=>{
                return (
                    <div className="subordinate-recharge-record-box" key={index}>
                        <div className="subordinate-recharge-record-item">
                            <h3>充值信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">会员账号：</span>
                                <span className="content">{item.username}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">订单号：</span>
                                <span className="content">{item.order_sn}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">充值方式：</span>
                                <span className="content">{typeFilter(item.type)}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">充值金额：</span>
                                <span className="content">{item.amount}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">充值时间：</span>
                                <span className="content">{item.created_at}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">充值状态：</span>
                                <span className="content">{item.status}</span>
                            </p>
                        </div>
                        <div className="subordinate-recharge-record-item">
                            <h3>审核信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">审核时间：</span>
                                <span className="content">{item.check_time}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">审核人：</span>
                                <span className="content">{item.checkor}</span>
                            </p>
                        </div>
                    </div>
                )
            })}
            {showNoData && <NoData />}
        </div>
    )
}
//最后要配合forwardRef
const SubordinateRechargeRecord = forwardRef(Index);
export default SubordinateRechargeRecord;