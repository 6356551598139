import { useState, useRef } from 'react'

const useWebsocket = ({ url }) => {
    const ws = useRef(null)
    const [wsData, setMessage] = useState('')
    const [readyState, setReadyState] = useState({ key: 0, value: '正在链接中' })
    const [closeCode, setCloseCode] = useState(1000) // 通讯正常关闭的code值
    const creatWebSocket = () => {
        const stateArr = [
            { key: 0, value: '正在链接中' },
            { key: 1, value: '已经链接并且可以通讯' },
            { key: 2, value: '连接正在关闭' },
            { key: 3, value: '连接已关闭或者没有链接成功' }
        ]
        try {
            ws.current = new WebSocket(url)
            ws.current.onopen = () => {
                setReadyState(stateArr[ws.current?.readyState ?? 0])
            }
            ws.current.onclose = (e) => {
                setCloseCode(e.code);
                setReadyState(stateArr[ws.current?.readyState ?? 0])
            }
            ws.current.onerror = () => {
                setReadyState(stateArr[ws.current?.readyState ?? 0])
            }

            ws.current.onmessage = (e) => {
                setMessage(e.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // const webSocketInit = () => {
    //     if (!ws.current || ws.current.readyState === 3) {
    //         creatWebSocket()
    //     }
    // }
    //  关闭 WebSocket
    const closeWebSocket = () => {
        ws.current?.close()
    }

    const reconnect = () => {
        try {
            closeWebSocket()
            ws.current = null
            creatWebSocket()
        } catch (e) {
            console.log(e)
        }
    }

    // useEffect(() => {
    //     webSocketInit()
    //     return () => {
    //         ws.current?.close()
    //     }
    // }, [ws]) // eslint-disable-line react-hooks/exhaustive-deps

    return {
        ws,
        wsData,
        readyState,
        closeCode,
        closeWebSocket,
        reconnect
    }
}
export default useWebsocket