import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './store'
import 'lib-flexible'
import reportWebVitals from './reportWebVitals';
// 添加全局事件
React.$windowScrollTop = function() {
  window.scrollTo(0,0)
}

ReactDOM.render(
  <Provider store={store}><App /></Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
