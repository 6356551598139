import React, { useEffect,useState } from 'react'
import './index.css'
import {getCashStatement} from '@api'

export default function CashReport(props) {
    // 存储报表数据
    const [cashReportData, setCashReportData] = useState([])
    useEffect(()=>{
        getCashStatement({...props.cashReportParams,username:props.userName || ''}).then(res=>{
            if(res.result===200000) {
                setCashReportData(res.data)
            }
        })
    },[props.cashReportParams]) // eslint-disable-line react-hooks/exhaustive-deps
    // 收入报表-表格头部
    const incomingsTableHead = ['收入','收入明细','数量']
    // 收入报表-数据
    const incomingsData = [
        {
          label: '线下支付',
          detail: `${cashReportData.recharge_offline_amount || '0.00'}元(${cashReportData.recharge_offline_user_count || 0}人)`,
          incount: `${cashReportData.recharge_offline_count || 0}(单)`,
        },
        {
          label: '人工存入<br />(计入报表)',
          detail: `${cashReportData.recharge_artificial_amount || '0.00'}元(${cashReportData.recharge_artificial_user_count || 0}人)`,
          incount: `${cashReportData.recharge_artificial_count || 0}(单)`
        },
        {
          label: '线上支付',
          detail: `${cashReportData.recharge_online_amount || '0.00'}元(${cashReportData.recharge_online_user_count || 0}人)`,
          incount: `${cashReportData.recharge_online_count || 0}(单)`
        },
        {
          label: '实际收入',
          detail: `${cashReportData.real_income || '0.00'}元`,
          incount: ''
        },
        {
          label: '实际盈亏',
          detail: `${cashReportData.real_profit || '0.00'}元`,
          incount: ''
        }
    ]
    // 支出报表-表格头部
    const expendTableHead = ['支出','支出明细','数量']
    // 收入报表-数据
    const expendData = [
        {
          label: '第三方出款',
          detail: `${cashReportData.third_withdraw_amount || '0.00'}元(${cashReportData.third_withdraw_user_count || 0}人)`,
          incount: `${cashReportData.third_withdraw_count || 0}(单)`,
        },
        {
          label: '人工扣款<br />(计入报表)',
          detail: `${cashReportData.withdraw_amount || '0.00'}元(${cashReportData.withdraw_user_count || 0}人)`,
          incount: `${cashReportData.withdraw_count || 0}(单)`
        },
        {
          label: '人工出款',
          detail: `${cashReportData.withdraw_artificial_amount || '0.00'}元(${cashReportData.withdraw_artificial_user_count || 0}人)`,
          incount: `${cashReportData.withdraw_artificial_count || 0}(单)`
        },
        {
          label: '实际出款',
          detail: `${cashReportData.real_expenses || '0.00'}元`,
          incount: ''
        },
        {
          label: '返佣金额',
          detail: `${cashReportData.commission || '0.00'}元(${cashReportData.commission_user_cnt || 0}人)`,
          incount: ''
        },
        {
          label: '返水金额',
          detail: `${cashReportData.rake || '0.00'}元(${cashReportData.rake_user_cnt || 0}人)`,
          incount: ''
        },
        {
          label: '活动彩金',
          detail: `${cashReportData.activity_jackpot || '0.00'}元(${cashReportData.activity_user_cnt || 0}人)`,
          incount: ''
        },
        {
          label: '充值优惠',
          detail: `${cashReportData.deposit_jackpot || '0.00'}元(${cashReportData.deposit_jackpot_user_cnt || 0}人)`,
          incount: ''
        }
    ]
    return (
        <div className="cash-report-wrap">
            <div className="incomings-wrap">
                <h3>收入数据</h3>
                <table className="incomings-table">
                    <thead>
                        <tr>
                            {
                                incomingsTableHead.map(item=>{
                                    return <th key={item}>{item}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            incomingsData.map((item,index)=>{
                                return (
                                    <tr key={index}>
                                        <td dangerouslySetInnerHTML={{__html:item.label}}></td>
                                        <td>{item.detail}</td>
                                        <td>{item.incount}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="incomings-wrap">
                <h3>支出数据</h3>
                <table className="incomings-table">
                    <thead>
                        <tr>
                            {
                                expendTableHead.map(item=>{
                                    return <th key={item}>{item}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            expendData.map((item,index)=>{
                                return (
                                    <tr key={index}>
                                        <td dangerouslySetInnerHTML={{__html:item.label}}></td>
                                        <td>{item.detail}</td>
                                        <td>{item.incount}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
