import {post,get} from '../axios'
const getTimestamp = new Date().getTime()

// 登录
export const login = (params) => {
    return post(`/api/agent/login?t=${getTimestamp}`,params)
}

// 平台数据及代理信息
export const getPlatformInfo = (params) => {
    return get(`/api/report/platform`,params)
}

// 平台图形报表
export const getPlatChart = (params) => {
    return get(`/api/report/chart`,params)
}

// 平台近一周数据明细
export const getPlatWeekDetail = (params) => {
    return get(`/api/report/weekDetail`,params)
}

// 现金报表
export const getCashStatement = (params) => {
    return get(`/api/report/cashStatement`,params)
}

// 损益报表
export const getProfitLoss = (params) => {
    return get(`/api/report/getProfitLoss`,params)
}

// 导出损益报表
export const getProfitLossExport = (params) => {
    return get(`/api/report/getProfitLossExport`,params)
}

// 下载列表
export const getDownloadList = (params) => {
    return get(`/api/download/lists`,params)
}

// 下线会员列表
export const getUserList = (params) => {
    return get(`/api/child/userList`,params)
}

// 导出下线会员列表
export const getUserListExport = (params) => {
    return get(`/api/child/userListExport`,params)
}

// 下级提现记录
export const getSubordinateWithdrawRecord = (params) => {
    return get(`/api/child/withdrawalLog`,params)
}

// 下级充值记录
export const getSubordinateRechargeRecord = (params) => {
    return get(`/api/child/rechargeLog`,params)
}

// 下级返佣记录
export const getSubordinateRebateRecord = (params) => {
    return get(`/api/child/rebateLog`,params)
}

// 下级投注记录-所有平台
export const getSubordinateBetRecordAll = (params) => {
    return get(`/api/betReport/getAll`,params)
}

// 下级投注记录-真人
export const getSubordinateBetRecordLive = (params) => {
    return get(`/api/betReport/getVideoList`,params)
}

// 下级投注记录-电子
export const getSubordinateBetRecordEgame = (params) => {
    return get(`/api/betReport/getEgameList`,params)
}

// 下级投注记录-电竞
export const getSubordinateBetRecordEgc = (params) => {
    return get(`/api/betReport/getEgcList`,params)
}

// 下级投注记录-棋牌
export const getSubordinateBetRecordPoker = (params) => {
    return get(`/api/betReport/getPokerList`,params)
}

// 下级投注记录-体育
export const getSubordinateBetRecordSport = (params) => {
    return get(`/api/betReport/getSportsList`,params)
}

// 下级投注记录-彩票
export const getSubordinateBetRecordLottery = (params) => {
    return get(`/api/betReport/getLotteryList`,params)
}

// 下级投注记录-查看详情
export const getBetOrderDetail = (params) => {
    return get(`/api/betReport/bettingorderdetail`,params)
}

// 游戏列表
export const getAllGameList = (params) => {
    return get(`/api/games/categorygameslist`,params)
}

// 总代提现记录
export const getGeneralAgentWithdrawRecord = (params) => {
    return get(`/api/cash/withdrawalLog`,params)
}

// 总代返佣记录
export const getGeneralAgentRebateRecord = (params) => {
    return get(`/api/cash/rebate`,params)
}

// 全平台投注记录导出
export const getAllExport = (params) => {
    return get(`/api/betReport/getAllExport`,params)
}

// 真人投注记录导出
export const getLiveExport = (params) => {
    return get(`/api/betReport/getVideoListExport`,params)
}

// 电子投注记录导出
export const getEgameExport = (params) => {
    return get(`/api/betReport/getEgameListExport`,params)
}

// 电竞投注记录导出
export const getEgcExport = (params) => {
    return get(`/api/betReport/getEgcListExport`,params)
}

// 棋牌投注记录导出
export const getPokerExport = (params) => {
    return get(`/api/betReport/getPokerListExport`,params)
}

// 体育投注记录导出
export const getSportExport = (params) => {
    return get(`/api/betReport/getSportsListExport`,params)
}

// 彩票投注记录导出
export const getLotteryExport = (params) => {
    return get(`/api/betReport/getLotteryListExport`,params)
}

// 总代银行卡信息
export const getAgentBankInfo = (params) => {
    return get(`/api/agent/agentBankInfo`,params)
}

// 总代取款
export const doWithdraw = (params) => {
    return post(`/api/cash/withdraw`,params)
}

// 余额（在总代信息接口里面）
export const getAgentInfo = (params) => {
    return get(`/api/agent/agentInfo`,params)
}

// 修改密码
export const doChangePassword = (params) => {
    return post(`/api/agent/updatepassword`,params)
}

// 退出登录
export const doLogout = (params) => {
    return post(`/api/agent/logout`,params)
}