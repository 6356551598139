import React, { useEffect,useState,useImperativeHandle,forwardRef } from 'react'
import './index.less'
import {NoData} from '@components'
import {getProfitLoss} from '@api'
import { Pagination } from 'antd-mobile'

function Index(props,ref) {
    // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        showNoData
    }))
    // 存储报表数据
    const [profitReportData, setProfitReportData] = useState({})
    // 是否显示无数据结构
    const [showNoData, setShowNoData] = useState(true)
    // 请求数据的页码
    const [curPage, setCurPage] = useState(1)
    useEffect(()=>{
        getProfitLoss({...props.profitReportParams,username:props.userName,perPage:10,page:curPage}).then(res=>{
            React.$windowScrollTop()
            if(res.result===200000) {
                setProfitReportData(res.data)
                if(JSON.stringify(res.data) === "{}" || res.data.list.length === 0) {
                    setShowNoData(true)
                } else {
                    setShowNoData(false)
                }
            }
        })
    },[props.profitReportParams,curPage]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="profit-report-wrap">
            {!showNoData&&<Pagination className="profit-report-pagination" total={profitReportData.pageCount} current={curPage} onChange={v=>setCurPage(v)} />}
            {!showNoData&&profitReportData.list&&
                <div className="profit-report-wrap-wrap">
                    {profitReportData.list.map((item,index)=>{
                        return (
                            <div className="profit-report-box" key={index}>
                                <div className="profit-report-item">
                                    <h3>会员信息</h3>
                                    <p className="report-info-wrap">
                                        <span className="label">会员账号：</span>
                                        <span className="content">{item.username}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">注册时间：</span>
                                        <span className="content">{item.register_time}</span>
                                    </p>
                                </div>
                                <div className="profit-report-item">
                                    <h3>存款信息</h3>
                                    <p className="report-info-wrap">
                                        <span className="label">总存款：</span>
                                        <span className="content">{item.recharge_amount}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">存款次数：</span>
                                        <span className="content">{item.recharge_cnt}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总取款：</span>
                                        <span className="content">{item.withdraw_amount}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">取款次数：</span>
                                        <span className="content">{item.withdraw_cnt}</span>
                                    </p>
                                </div>
                                <div className="profit-report-item">
                                    <h3>投注与派彩</h3>
                                    <p className="report-info-wrap">
                                        <span className="label">总优惠彩金：</span>
                                        <span className="content">{item.discount}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总投注：</span>
                                        <span className="content">{item.bet}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总有效投注：</span>
                                        <span className="content">{item.valid_bet}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总派彩：</span>
                                        <span className="content">{item.win}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总返水：</span>
                                        <span className="content">{item.rake}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总输赢：</span>
                                        <span className="content">{item.profit}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总佣金：</span>
                                        <span className="content">{item.commission}</span>
                                    </p>
                                    <p className="report-info-wrap">
                                        <span className="label">总损益：</span>
                                        <span className="content">{item.win_loss}</span>
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                    <div className="profit-report-statistics-wrap">
                            <div className="statistics-box">
                                <div className="statistics-item">
                                    <span className="label">总存款</span>
                                    <span className="content">{profitReportData.total.recharge_amount}</span>
                                </div>
                                <div className="statistics-item">
                                    <span className="label">总取款</span>
                                    <span className="content">{profitReportData.total.withdraw_amount}</span>
                                </div>
                                <div className="statistics-item">
                                    <span className="label">总优惠彩金</span>
                                    <span className="content">{profitReportData.total.discount}</span>
                                </div>
                            </div>
                            <div className="statistics-box">
                                <div className="statistics-item">
                                    <span className="label">总返水</span>
                                    <span className="content">{profitReportData.total.rake_amount}</span>
                                </div>
                                <div className="statistics-item">
                                    <span className="label">总佣金</span>
                                    <span className="content">{profitReportData.total.commission}</span>
                                </div>
                                <div className="statistics-item">
                                    <span className="label">总投注</span>
                                    <span className="content">{profitReportData.total.bet}</span>
                                </div>
                            </div>
                            <div className="statistics-box">
                                <div className="statistics-item">
                                    <span className="label">总有效投注</span>
                                    <span className="content">{profitReportData.total.valid_bet}</span>
                                </div>
                                <div className="statistics-item">
                                    <span className="label">总派彩</span>
                                    <span className="content">{profitReportData.total.win}</span>
                                </div>
                                <div className="statistics-item">
                                    <span className="label">总输赢</span>
                                    <span className="content">{profitReportData.total.profit}</span>
                                </div>
                            </div>
                            <div className="statistics-box">
                                <div className="statistics-item">
                                    <span className="label">总损益</span>
                                    <span className="content">{profitReportData.total.win_loss}</span>
                                </div>
                            </div>
                    </div>
                    <p className="profit-report-tip">资金汇总（损益=存款金额-去款金额-赠送优惠彩金-返水金额-返佣金额</p>
                </div>
            }
            {showNoData && <NoData />}
        </div>
    )
}
//最后要配合forwardRef
const ProfitReport = forwardRef(Index);
export default ProfitReport;