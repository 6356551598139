import React, { useEffect,useState,forwardRef,useImperativeHandle } from 'react'
import './index.less'
import {NoData} from '@components'
import {getSubordinateWithdrawRecord} from '@api'
import { Pagination } from 'antd-mobile'

function Index(props,ref) {
    // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        setCurPage
    }))
    // 存储报表数据
    const [withdrawRecordData, setWithdrawRecordData] = useState({})
    // 是否显示无数据结构
    const [showNoData, setShowNoData] = useState(true)
    // 请求数据的页码
    const [curPage, setCurPage] = useState(1)
    useEffect(()=>{
        getSubordinateWithdrawRecord({...props.subordinateWithdrawRecordParams,username:props.username,status:props.status,perPage:10,page:curPage}).then(res=>{
            React.$windowScrollTop()
            if(res.result===200000) {
                setWithdrawRecordData(res.data)
                if(!res.data.list.length) {
                    setShowNoData(true)
                } else {
                    setShowNoData(false)
                }
            }
        })
    },[props.subordinateWithdrawRecordParams,curPage]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="subordinate-withdraw-record-wrap">
            {!showNoData&&<Pagination className="subordinate-withdraw-record-pagination" total={withdrawRecordData.pageCount} current={curPage} onChange={v=>setCurPage(v)} />}
            {!showNoData&&withdrawRecordData.list.map((item,index)=>{
                return (
                    <div className="subordinate-withdraw-record-box" key={index}>
                        <div className="subordinate-withdraw-record-item">
                            <h3>提现信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">会员账号：</span>
                                <span className="content">{item.username}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">提现金额：</span>
                                <span className="content">{item.money}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">提现时间：</span>
                                <span className="content">{item.created_time}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">提现状态：</span>
                                <span className="content">{item.status}</span>
                            </p>
                        </div>
                        <div className="subordinate-withdraw-record-item">
                            <h3>审核信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">审核时间：</span>
                                <span className="content">{item.check_time}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">审核人：</span>
                                <span className="content">{item.checkor}</span>
                            </p>
                        </div>
                    </div>
                )
            })}
            {showNoData && <NoData />}
        </div>
    )
}
//最后要配合forwardRef
const SubordinateWithdrawRecord = forwardRef(Index);
export default SubordinateWithdrawRecord;