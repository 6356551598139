import React from 'react'
import {Link} from 'react-router-dom'
import './index.less'

export default function PersonalBox() {
    return (
        <Link to="/personal" className="personalBox-wrap">
            <img src={require('./images/user.png').default} alt="" />
            <span>我的</span>
        </Link>
    )
}
