import axios from 'axios';
import { ActivityIndicator,Toast } from "antd-mobile"
import doCookies from '@utils/cookies'
import { render } from 'react-dom'
/**
 * 设置超时时间和跨域是否允许携带凭证
 */
axios.defaults.timeout = 60000; //60秒
axios.defaults.withCredentials = true;
/**
 * 设置post请求头
 * application/json;charset=UTF-8   JSON格式
 * application/x-www-form-urlencoded;charset=UTF-8  Form表单格式
 */
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';

let httpRequestCount = 0
const showLoading = () => {
    if (httpRequestCount === 0) {
        const loadingContainer = document.createElement('div')
        loadingContainer.setAttribute('id', 'axiosLoading')
        document.body.appendChild(loadingContainer)
        render(<ActivityIndicator toast text='Loading...' animating />, loadingContainer)
    }
    httpRequestCount++
}
const hideLoading = () => {
    httpRequestCount--
    if (httpRequestCount === 0) {
        document.querySelector('body').removeChild(document.querySelector('#axiosLoading'))
    }
}

/**
 * 请求拦截器
 */
axios.interceptors.request.use(config => {
    let token = doCookies.getCookie('token')
    if (token && token !== 'undefined') {
        config.headers['access-token'] = `${token}`;
    }
    config.data = Object.assign({}, config.data)
    showLoading()
    return config;
}, error => {
    hideLoading()
    return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(
    res => {
        if (res.data.result === 101041) {
            // 登录失效，清除token等数据，刷新页面
            doCookies.delCookie("token")
            Toast.offline(res.data.msg,1.5)
            window.location.href = window.location.origin + "/#/login";
        }
        hideLoading()
        return res.data;
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        // if (response.status === 200) {
        //     return Promise.resolve(response);
        // } else {
        //     return Promise.reject(response);
        // }
    },
    err => {
        hideLoading()
        return Promise.reject(err);
        // if (error.response.status) {
        //     switch (error.response.status) {
        //       // 401: 未登录
        //       // 未登录则跳转登录页面，并携带当前页面的路径
        //       // 在登录成功后返回当前页面，这一步需要在登录页操作。
        //       case 401:
        //         vant.Toast.fail("身份验证失败，请关闭重新进入。");
        //         break;
      
        //       // 403 token过期
        //       // 登录过期对用户进行提示
        //       // 清除本地token和清空vuex中token对象
        //       // 跳转登录页面
        //       case 403:
        //         vant.Toast.fail("登录过期，请关闭重新进入。");
        //         // 清除token
        //         break;
      
        //       // 404请求不存在
        //       case 404:
        //         vant.Toast.fail("您访问的网页不存在。");
        //         break;
        //       // 其他错误，直接抛出错误提示
        //       default:
        //         vant.Toast.fail(error.response.data.message);
        //     }
        //     return Promise.reject(error.response);
        // }
    }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: {
                ...params
            },
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(`${url}`, params)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            })
    });
}

// 对外暴露
export { post, get }