import Loadable from 'react-loadable'
import {Loading} from '../components'

// 懒加载
const Home = Loadable({
    loader: () => import('./Home'),
    loading:Loading
})
const Login = Loadable({
    loader: () => import('./Login'),
    loading:Loading
})
const RechargeWithDrawRecord = Loadable({
    loader: () => import('./RechargeWithDrawRecord'),
    loading:Loading
})
const BettingRebate = Loadable({
    loader: () => import('./BettingRebate'),
    loading:Loading
})
const Members = Loadable({
    loader: () => import('./Member'),
    loading:Loading
})
const WithDraw = Loadable({
    loader: () => import('./WithDraw'),
    loading:Loading
})
const DownloadList = Loadable({
    loader: () => import('./DownloadList'),
    loading:Loading
})
const Personal = Loadable({
    loader: () => import('./Personal'),
    loading:Loading
})
export {Home,Login,RechargeWithDrawRecord,BettingRebate,Members,WithDraw,DownloadList,Personal}