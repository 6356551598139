import React, {useState,forwardRef,useImperativeHandle,useEffect} from 'react'
import './index.less'
import date from '@utils/date'
import moment from 'moment'
import { Calendar, List, Picker } from 'antd-mobile'
function Index(props,ref) {
    // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        dateParams,
        setRangeValue,
        setCurrTimeValue
    }))
    // 是否显示日期范围
    const [showCalendar, setShowCalendar] = useState(false)
    // 日期范围显示值
    const [rangeValue, setRangeValue] = useState(`${moment(date.currDay()).format('MM-DD')} 至 ${moment(date.currDay()).format('MM-DD')}`)
    // 时间类型数据
    const currTimeData =  [{label:'今日',value:'jr'},{label:'昨日',value:'zr'},{label:'本周',value:'bz'},{label:'本月',value:'by'},{label:'上月',value:'sy'}]
    // 时间类型数据value值
    const [currTimeValue, setCurrTimeValue] = useState(['jr'])
    // 存储开始结束日期--日期范围
    const [dateParams, setDateParams] = useState({startDate:moment().startOf('day').format('YYYY-MM-DD'),endDate:moment().endOf('day').format('YYYY-MM-DD')})
    useEffect(() => {
        if(props.isNoDate) {
            setRangeValue('开始 至 结束')
            setDateParams({startDate:'',endDate:''})
        } else {
            setRangeValue(`${moment(date.currDay()).format('MM-DD')} 至 ${moment(date.currDay()).format('MM-DD')}`)
            setDateParams({startDate:moment().startOf('day').format('YYYY-MM-DD'),endDate:moment().endOf('day').format('YYYY-MM-DD')})
        }
    }, [props.isNoDate])
    // 选择日期范围事件
    function handleSelecDateRange(start,end) {
        setRangeValue(`${moment(start).format('MM-DD')} 至 ${moment(end).format('MM-DD')}`)
        setDateParams({startDate:moment(start).format('YYYY-MM-DD'),endDate:moment(end).format('YYYY-MM-DD')})
        setShowCalendar(false)
    }
    // 选择搜索时间
    function handleChangeTimePicker(value) {
        setCurrTimeValue(value)
        getRangeDate(value)
    }
    // 按照时间数据显示日期范围
    function getRangeDate(value) {
        // 今日
        if(value[0] === 'jr') {
            setRangeValue(`${moment(date.currDay()).format('MM-DD')} 至 ${moment(date.currDay()).format('MM-DD')}`)
            setDateParams({startDate:moment(date.currDay()).format('YYYY-MM-DD'),endDate:moment(date.currDay()).format('YYYY-MM-DD')})
        }
        // 昨日
        if(value[0] === 'zr') {
            let startDate = moment().subtract(1, "days").format('YYYY-MM-DD')
            let endDate = moment().subtract(1, "days").format('YYYY-MM-DD')
            setRangeValue(`${moment(startDate).format('MM-DD')} 至 ${moment(endDate).format('MM-DD')}`)
            setDateParams({startDate:startDate,endDate:endDate})
        }
        // 本周
        if(value[0] === 'bz') {
            let startDate = moment().day("Monday").format('YYYY-MM-DD')
            let endDate = moment().day("Monday").day(+7).format('YYYY-MM-DD')
            setRangeValue(`${moment(startDate).format('MM-DD')} 至 ${moment(endDate).format('MM-DD')}`)
            setDateParams({startDate:startDate,endDate:endDate})
        }
        // 本月
        if(value[0] === 'by') {
            let startDate = moment().startOf("month").format('YYYY-MM-DD')
            let endDate = moment().endOf("month").format('YYYY-MM-DD')
            setRangeValue(`${moment(startDate).format('MM-DD')} 至 ${moment(endDate).format('MM-DD')}`)
            setDateParams({startDate:startDate,endDate:endDate})
        }
        // 上月
        if(value[0] === 'sy') {
            let startDate = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD')
            let endDate = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')
            setRangeValue(`${moment(startDate).format('MM-DD')} 至 ${moment(endDate).format('MM-DD')}`)
            setDateParams({startDate:startDate,endDate:endDate})
        }
    }
    return (
        <div className="mydate">
            <div className="mydate-range-wrap" onClick={()=>setShowCalendar(true)}>
                <List.Item arrow="down" className="mydate-range-list-item">
                    {rangeValue}
                </List.Item>
            </div>
            <div className="mypicker">
                <Picker title="请选择" data={currTimeData} value={currTimeValue} cols={1} onChange={v=>handleChangeTimePicker(v)}>
                    <List.Item arrow="down"></List.Item>
                </Picker>
            </div>
            <Calendar
                showShortcut
                visible={showCalendar}
                minDate={new Date(+new Date() - 31536000000)}
                maxDate={new Date(+new Date())}
                onConfirm={(start,end)=>handleSelecDateRange(start,end)}
                onCancel={()=>setShowCalendar(false)}
            />
        </div>
    )
}
//最后要配合forwardRef
const MyDatePicker = forwardRef(Index);
export default MyDatePicker;