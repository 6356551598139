let now = new Date(Date.now())
let date = {
    currDay() {
        return now;
    },
    currLastMonth() {
        return new Date(new Date().setDate(new Date().getDate()-30));
    },
    /**
     * 将时间戳或者中国标准时间处理成 2018-05-01 00:00:00  这种格式
     * @param {时间戳或者中国标准时间} timestamp 
     * @param {一状态 } state   ture要时分秒  false不要时分秒 
     */
    timestampToTime(timestamp,state=false) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = ((date.getMonth()+1) >9 ? (date.getMonth()+1) : '0'+(date.getMonth()+1)) + '-';
        var D = (date.getDate() > 9 ? date.getDate() : '0'+date.getDate());
        var h = (date.getHours()> 9? date.getHours() : '0'+date.getHours())+ ':';
        var m = (date.getMinutes() > 9 ? date.getMinutes() : '0'+date.getMinutes())+ ':';
        var s = date.getSeconds() > 9 ? date.getSeconds() : '0'+date.getSeconds();
        return state?Y+M+D+' '+h+m+s:Y+M+D;
    }
}

export default date