let doCookies = {
    // 设置cookies
    setCookie(key,value,expiredays) {
        let date = new Date();
        expiredays = expiredays || 1;
        date.setDate(date.getDate()+expiredays);
        document.cookie = key+'='+encodeURIComponent(value)+';expires='+date.toGMTString();
    },
    // 获取cookies
    getCookie(key) {
        let reg = RegExp('(^| )'+key+'=([^;]+)(;|$)');
        if (document.cookie.match(reg))
            return decodeURIComponent(document.cookie.match(reg)[2]);
        else
            return null;
    },
    // 删除cookies
    delCookie(key) {
        let date = new Date();
        date.setTime(date.getTime() - 1);
        let delValue = this.getCookie(key);
        if (!!delValue) {
            document.cookie = key+'='+delValue+';expires='+date.toGMTString();
        }
    }
}

export default doCookies