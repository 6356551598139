import React, { useEffect,useState, forwardRef, useImperativeHandle } from 'react'
import './index.less'
import {NoData} from '@components'
import {getSubordinateRebateRecord} from '@api'
import { Pagination } from 'antd-mobile'

function Index(props,ref) {
    // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        setCurPage
    }))
    // 存储报表数据
    const [subordinateRebateRecordData, setSubordinateRebateRecordData] = useState({})
    // 是否显示无数据结构
    const [showNoData, setShowNoData] = useState(true)
    // 请求数据的页码
    const [curPage, setCurPage] = useState(1)
    useEffect(()=>{
        getSubordinateRebateRecord({...props.subordinateRebateRecordParams,username:props.username,perPage:10,page:curPage}).then(res=>{
            React.$windowScrollTop()    
            if(res.result===200000) {
                setSubordinateRebateRecordData(res.data)
                if(!res.data.list.length) {
                    setShowNoData(true)
                } else {
                    setShowNoData(false)
                }
            }
        })
    },[props.subordinateRebateRecordParams,curPage]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <div className="subordinate-rebate-record-wrap">
            {!showNoData&&<Pagination className="subordinate-rebate-record-pagination" total={subordinateRebateRecordData.pageCount} current={curPage} onChange={v=>setCurPage(v)} />}
            {!showNoData&&subordinateRebateRecordData.list.map((item,index)=>{
                return (
                    <div className="subordinate-rebate-record-box" key={index}>
                        <div className="subordinate-rebate-record-item">
                            <h3>返佣信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">会员账号：</span>
                                <span className="content">{item.username}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">返佣金额：</span>
                                <span className="content">{item.amount}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">返佣时间：</span>
                                <span className="content">{item.settlement_time}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">所属时间：</span>
                                <span className="content">{item.created_at}</span>
                            </p>
                        </div>
                    </div>
                )
            })}
            {showNoData && <NoData />}
        </div>
    )
}
//最后要配合forwardRef
const SubordinateRebateRecord = forwardRef(Index);
export default SubordinateRebateRecord;