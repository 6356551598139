// reducer就是根据action来对state进行操作
import * as types from '../actionTypes'

function getItem (val) {
    return window.localStorage.getItem(val)
}
const defaultState = {
    agentInfo: getItem('agentInfo') || null
}  //默认数据

const agentReducer = (state = defaultState,action) => {  //就是一个方法函数
    switch(action.type) {
        case types.GET_AGENT_INFO:
            localStorage.setItem('agentInfo',action.payload)
            return {...state,agentInfo:action.payload}
        default:
            return state
    }
}
export default agentReducer