import {Home,Login,RechargeWithDrawRecord,BettingRebate,Members,WithDraw,DownloadList,Personal} from '../views'
export const mainRoutes = [
    {pathname:'/',component:Home,requireLogin:true},
    {pathname:'/login',component:Login},
    {pathname:'/rechargeWithDrawRecord',component:RechargeWithDrawRecord,requireLogin:true},
    {pathname:'/bettingRebate',component:BettingRebate,requireLogin:true},
    {pathname:'/member',component:Members,requireLogin:true},
    {pathname:'/withdraw',component:WithDraw,requireLogin:true},
    {pathname:'/downloadList',component:DownloadList,requireLogin:true},
    {pathname:'/personal',component:Personal,requireLogin:true}
]