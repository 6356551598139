import React, { Fragment,useEffect,useState } from 'react'
import './index.css'
import ReactEcharts from 'echarts-for-react'
import {getPlatChart,getPlatWeekDetail} from '@api'

export default function AgentStatistics(props) {
    // 存储图形数据
    const [chartData, setChartData] = useState({})
    // 存储近7日数据明细
    const [weekDetailData, setWeekDetailData] = useState([])
    useEffect(() => {
        getPlatChart(props.platChartParams).then(res=>{
            if(res.result===200000) {
                setChartData(res.data)
            }
        })
        getPlatWeekDetail(props.platChartParams).then(res=>{
            if(res.result===200000) {
                let arr = Object.keys(res.data)
                let data = arr.map((item, i) => {
                    res.data[item].key = i
                    res.data[item].date = item
                    return res.data[item]
                })
                setWeekDetailData(data)
            }
        })
    }, [props.platChartParams])
    // 充值报表
    const rechargeEchartsOption = () => {
        let List = chartData.rechargeList || [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
        ]
        let defineList = [
            [List[0][0], List[1][0], List[2][0]],
            [List[0][2], List[1][2], List[2][2]],
            [List[0][1], List[1][1], List[2][1]]
        ]
        return {
            backgroundColor: '#fff',
            title: {
                text: `充值报表 -- 总充值金额：${chartData.rechargeAmount || '0.00'}元/${chartData.rechargeNum || 0}人`,
                padding: [10, 0, 0, 10],
                textStyle: {
                    color: '#2E303B',
                    fontSize: 16,
                    fontWeight: '500',
                }
            },
            grid: {
                top: '25%',
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: chartData.rechargeLabels || ['线下入款', '线上入款', '人工入款']
            },
            yAxis: {
                type: 'value',
                axisTick: { inside: true },
                scale: true,
                axisLabel: {
                    margin: 2,
                    formatter: function (value) {　　//主要调整部分
                        if (value >= 10000 && value < 10000000) {
                            value = value / 10000 + "万";
                        } else if (value >= 10000000) {
                            value = value / 10000000 + "千万";
                        }
                        return value;
                    }
                },
                "splitLine": {//网格线 默认true
                    "show": true
                }
            },
            legend: {
                data: ['笔数', '人数', '金额'],
                top: '30px'
            },
            series: [
                {
                    name: '笔数',
                    type: 'bar',
                    data: defineList[0],
                    barWidth: 15,
                    itemStyle: {
                        normal: {
                            color: '#3BA1FF'
                        }
                    }
                },
                {
                    name: '人数',
                    type: 'bar',
                    data: defineList[1],
                    barWidth: 15,
                    itemStyle: {
                        normal: {
                            color: '#4FCB74'
                        }
                    }
                },
                {
                    name: '金额',
                    type: 'bar',
                    data: defineList[2],
                    barWidth: 15,
                    itemStyle: {
                        normal: {
                            color: '#FBD438'
                        }
                    }
                }
            ],
        }
    }
    // 出款报表
    const withdrawEchartsOption = () => {
        let List = chartData.withdrawList || [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
        ]
        let defineList = [
            [List[0][0], List[1][0], List[2][0]],
            [List[0][2], List[1][2], List[2][2]],
            [List[0][1], List[1][1], List[2][1]]
        ]
        return {
            backgroundColor: '#fff',
            title: {
                text: '总出款=第三方出款+人工出款+人工扣款',
                padding: [10, 0, 0, 10],
                itemGap: 8,
                textStyle: {
                    color: '#2E303B',
                    fontSize: 16,
                    fontWeight: '500',
                },
                subtext: `出款报表--总出款金额/人数：${chartData.withdrawAmount || '0.00'}元/${chartData.withdrawNum || 0}人`,
                subtextStyle: {
                    color: '#678CEF',
                    fontSize: 12,
                },
            },
            grid: {
                top: '30%',
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: chartData.withdrawLabels || ['第三方出款', '人工出款', '人工扣款']
            },
            yAxis: {
                type: 'value',
                axisTick: { inside: true },
                scale: true,
                axisLabel: {
                    margin: 2,
                    formatter: function (value) {　　//主要调整部分
                        if (value >= 10000 && value < 10000000) {
                            value = value / 10000 + "万";
                        } else if (value >= 10000000) {
                            value = value / 10000000 + "千万";
                        }
                        return value;
                    }
                },
                "splitLine": {//网格线 默认true
                    "show": true
                }
            },
            legend: {
                data: ['笔数', '人数', '金额'],
                top: '50px'
            },
            series: [
                {
                    name: '笔数',
                    type: 'bar',
                    data: defineList[0],
                    barWidth: 15,
                    itemStyle: {
                        normal: {
                            color: '#d48265'
                        }
                    }
                },
                {
                    name: '人数',
                    type: 'bar',
                    data: defineList[1],
                    barWidth: 15,
                    itemStyle: {
                        normal: {
                            color: '#2f4554'
                        }
                    }
                },
                {
                    name: '金额',
                    type: 'bar',
                    data: defineList[2],
                    barWidth: 15,
                    itemStyle: {
                        normal: {
                            color: '#ca8622'
                        }
                    }
                }
            ],
        }
    }
    // 现金报表-总交易额
    const transactionEchartsOption = () => {
        return {
            backgroundColor: '#fff',
            title: {
                text: `现金报表 -- 总交易额：${chartData.cashAmount || '0.00'}元`,
                padding: [10, 0, 0, 10],
                textStyle: {
                    color: '#2E303B',
                    fontSize: 16,
                    fontWeight: '500',
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: chartData.betLabels,
                axisLabel: {
                    interval: 0,//横轴信息全部显示    
                    rotate: 45,//45度角倾斜显示     
                },
            },
            yAxis: {
                type: 'value',
                axisTick: { inside: true },
                scale: true,
                axisLabel: {
                    margin: 2,
                    formatter: function (value) {　　//主要调整部分
                        if (value >= 10000 && value < 10000000) {
                            value = value / 10000 + "万";
                        } else if (value >= 10000000) {
                            value = value / 10000000 + "千万";
                        }
                        return value;
                    }
                },
                "splitLine": {//网格线 默认true
                    "show": true
                }
            },
            series: [
                {
                    name: '现金报表',
                    type: 'bar',
                    data: chartData.betList,
                    barWidth: 15,
                    itemStyle: {
                        normal: {
                            color: '#b3a43b',
                            label: {
                                show: true,//是否在图上展示数据
                                position:'top',//数据在柱状图顶部显示
                                textStyle: {
                                    color: '#000000' //数据颜色
                                }
                            }
                        }
                    }
                }
            ],
        }
    }
    // 近7日数据明细--表头
    const detailTableHead = ['日期','注册用户','首充用户','投注用户','返水用户']
    return (
        <Fragment>
            <div className="agent-statistics-wrap">
                <div className="agent-statistics-box">
                    <div className="agent-statistics-item">
                        <p className="label">账户余额</p>
                        <p className="money">{props.balance || '0.00'}</p>
                    </div>
                    <div className="agent-statistics-item">
                        <p className="label">总佣金</p>
                        <p className="money">{props.totalCommission || '0.00'}</p>
                    </div>
                </div>
                <div className="agent-statistics-box">
                    <div className="agent-statistics-item">
                        <p className="label">总有效投注</p>
                        <p className="money">{props.totalValidBet || '0.00'}</p>
                    </div>
                    <div className="agent-statistics-item">
                        <p className="label">首充金额</p>
                        <p className="money">{props.firstChargeAmount || '0.00'}</p>
                    </div>
                </div>
                <div className="agent-statistics-box">
                    <div className="agent-statistics-item">
                        <p className="label">注册用户数</p>
                        <p className="money">{props.registeredUserNum || '0.00'}</p>
                    </div>
                    <div className="agent-statistics-item">
                        <p className="label">下线总余额</p>
                        <p className="money">{props.totalBalance || '0.00'}</p>
                    </div>
                </div>
                <div className="agent-statistics-box">
                    <div className="agent-statistics-item">
                        <p className="label">总充值金额</p>
                        <p className="money">{props.totalChargeAmount || '0.00'}</p>
                    </div>
                    <div className="agent-statistics-item">
                        <p className="label">总取款金额</p>
                        <p className="money">{props.totalWithdrawAmount || '0.00'}</p>
                    </div>
                </div>
                <div className="agent-statistics-box">
                    <div className="agent-statistics-item">
                        <p className="label">彩金总额</p>
                        <p className="money">{props.jackpotAmount || '0.00'}</p>
                    </div>
                    <div className="agent-statistics-item">
                        <p className="label">总投注金额</p>
                        <p className="money">{props.totalBet || '0.00'}</p>
                    </div>
                </div>
                <div className="agent-statistics-box">
                    <div className="agent-statistics-item">
                        <p className="label">派彩总额</p>
                        <p className="money">{props.awardAmount || '0.00'}</p>
                    </div>
                    <div className="agent-statistics-item">
                        <p className="label">游戏输赢</p>
                        <p className="money">{props.totalReport || '0.00'}</p>
                    </div>
                </div>
            </div>
            <div className="echarts-wrap">
                <ReactEcharts option={rechargeEchartsOption()} />
                <ReactEcharts option={withdrawEchartsOption()} />
                <ReactEcharts option={transactionEchartsOption()} />
            </div>
            <div className="week-detail-wrap">
                <div className="week-detail-box">
                    <h3>近7日数据明细</h3>
                    <table className="detail-table">
                        <thead>
                            <tr>
                                {
                                    detailTableHead.map(item=>{
                                        return <th key={item}>{item}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                weekDetailData.map((item,index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>{item.date}</td>
                                            <td>{item.register_count}</td>
                                            <td>{item.first_user_count}</td>
                                            <td>{item.bet_user_num}</td>
                                            <td>{item.rake_user_num}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}
