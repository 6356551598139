import {combineReducers} from "redux"
import { createStore,compose, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import {userReducer,agentReducer} from './reducers/index'

// 全局你可以创建多个reducer 在这里统一在一起
// const rootReducers = combineReducers({siteInfosReducer})

const store = createStore(
  combineReducers({
    userReducer,
    agentReducer
  }), compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  ));

export default store;