// reducer就是根据action来对state进行操作
import * as types from '../actionTypes'
import doCookies from '@utils/cookies'

function getItem (val) {
    return window.localStorage.getItem(val)
}
const defaultState = {
    username: getItem('username') || null,
    token: doCookies.getCookie('token') || null,
    ws_url: getItem('ws_url') || null
}  //默认数据
const userReducer = (state = defaultState,action) => {  //就是一个方法函数
    switch(action.type) {
        case types.GET_USER_INFO:
            doCookies.setCookie('token',action.payload.token)
            localStorage.setItem('ws_url',action.payload.ws_url)
            localStorage.setItem('username',action.payload.username)
            return {...state,username:action.payload.username,token:action.payload.token,ws_url:action.payload.ws_url}
        default:
            return state
    }
}
export default userReducer