import { mainRoutes } from './router'
import { HashRouter as Router, Switch, Route,Redirect } from 'react-router-dom'
import { Modal } from 'antd-mobile'
import { useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import useWebsocket from '@utils/websocket'

function App() {
  const store = useSelector(state => ({
    token: state.userReducer.token,
    ws_url: state.userReducer.ws_url
  }))
  const token = store.token
  const ws_url = store.ws_url
  // websocket通讯
  const { ws, wsData, readyState, closeCode, closeWebSocket, reconnect } = useWebsocket({
    url: ws_url
  })
  // 登录+有ws地址，链接通讯
  useEffect(() => {
    if (!!token && !!ws_url) {
      reconnect()
    }
  }, [token, ws_url]) // eslint-disable-line react-hooks/exhaustive-deps
  // 监听readyState的变化处理重连跟心动
  // 定义定时器变量
  let timer = useRef();
  // 断线重连次数
  const [pingCount, setPingCount] = useState(0)
  useEffect(() => {
    if (readyState.key === 1) {
      console.log('websocket链接成功');
      clearInterval(timer.current)
      setPingCount(0)
      timer.current = setInterval(() => {
        if (ws.current.readyState === ws.current.OPEN) {
          ws.current.send('PING')
        }
      }, 30000);
    }
    // 当通讯非正常关闭，尝试重连10次，10次过后，正常关闭
    if (readyState.key === 3 && closeCode !== 1000) {
      if (pingCount < 10) {
        setPingCount(n => {
          return n + 1
        });
        clearInterval(timer.current)
        reconnect()
      } else {
        clearInterval(timer.current)
        closeWebSocket()
      }
    }
  }, [readyState]) // eslint-disable-line react-hooks/exhaustive-deps
  // 监听websocket返回的信息
  useEffect(() => {
    const waveObj = (wsData && JSON.parse(wsData)) || {}
    if (waveObj.event === 'output') {
      Modal.alert('提示',waveObj.message,[{ text: 'Ok', onPress: () => {handleComfirmModal()}}])
    }
  }, [wsData]) // eslint-disable-line react-hooks/exhaustive-deps
  // modal的确定按钮
  function handleComfirmModal() {
    if(window.location.hash==='#/downloadList') {
      window.location.reload()
    }
  }
  return (
    <Router>
      <Switch>
        {
          mainRoutes.map(route => {
            return <Route key={route.pathname} exact path={route.pathname} render={(routerProps) => {
              return (
                !route.requireLogin ? <route.component {...routerProps} /> : (token ? <route.component {...routerProps} /> : <Redirect to={{
                  pathname: '/login',
                  state: { from: routerProps.location }
                }} />)
              )
            }} />
          })
        }
      </Switch>
    </Router>
  );
}

export default App;
