import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import './index.less'
import { NoData } from '@components'
import {
    getSubordinateBetRecordAll, getSubordinateBetRecordLive, getSubordinateBetRecordEgame,
    getSubordinateBetRecordEgc, getSubordinateBetRecordPoker, getSubordinateBetRecordSport,
    getSubordinateBetRecordLottery, getBetOrderDetail
} from '@api'
import { Pagination, Button, Modal } from 'antd-mobile'

function Index(props, ref) {
    // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        showNoData,
        setCurPage,
        curPage,
        setShowNoData,
        setSubordinateBetRecordData
    }))
    // 存储报表数据
    const [subordinateBetRecordData, setSubordinateBetRecordData] = useState({})
    // 是否显示无数据结构
    const [showNoData, setShowNoData] = useState(true)
    // 请求数据的页码
    const [curPage, setCurPage] = useState(1)
    // 是否显示详情modal
    const [showDetailModel, setShowDetailModel] = useState(false)
    // 存储订单详情数据
    const [subordinateBetOrderDetailData, setSubordinateBetOrderDetailData] = useState({})
    // model组件兼容性
    const onWrapTouchStart = (e) => {
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }
    function closest(el, selector) {
        const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
        while (el) {
            if (matchesSelector.call(el, selector)) {
                return el;
            }
            el = el.parentElement;
        }
        return null;
    }
    useEffect(() => {
        React.$windowScrollTop()
        let params = {}
        params.startDate = `${props.subordinateBetRecordParams.startDate} 00:00:00`
        params.endDate = `${props.subordinateBetRecordParams.endDate} 23:59:59`
        let checkedStatusParams = props.statusParams.toString()
        // 全部平台
        if (!props.type) {
            getSubordinateBetRecordAll({ ...params, ...props.betMoneyParams, ...props.conditionParams, type: props.type, games_type: props.gamesType, time_type: props.timeType, award_result: props.awardResult, status:checkedStatusParams, pageSize: 10, page: curPage }).then(res => {
                if (res.result === 200000) {
                    setSubordinateBetRecordData(res.data)
                    if (!res.data.list.length) {
                        setShowNoData(true)
                    } else {
                        setShowNoData(false)
                    }
                }
            })
        }
        if (props.type === 'video') {
            getSubordinateBetRecordLive({ ...params, ...props.betMoneyParams, ...props.conditionParams, type: props.type, games_type: props.gamesType, time_type: props.timeType, award_result: props.awardResult, status:checkedStatusParams, pageSize: 10, page: curPage }).then(res => {
                if (res.result === 200000) {
                    setSubordinateBetRecordData(res.data)
                    if (!res.data.list.length) {
                        setShowNoData(true)
                    } else {
                        setShowNoData(false)
                    }
                }
            })
        }
        if (props.type === 'egame') {
            getSubordinateBetRecordEgame({ ...params, ...props.betMoneyParams, ...props.conditionParams, type: props.type, games_type: props.gamesType, time_type: props.timeType, award_result: props.awardResult, status:checkedStatusParams, pageSize: 10, page: curPage }).then(res => {
                if (res.result === 200000) {
                    setSubordinateBetRecordData(res.data)
                    if (!res.data.list.length) {
                        setShowNoData(true)
                    } else {
                        setShowNoData(false)
                    }
                }
            })
        }
        if (props.type === 'egc') {
            getSubordinateBetRecordEgc({ ...params, ...props.betMoneyParams, ...props.conditionParams, type: props.type, games_type: props.gamesType, time_type: props.timeType, award_result: props.awardResult, status:checkedStatusParams, pageSize: 10, page: curPage }).then(res => {
                if (res.result === 200000) {
                    setSubordinateBetRecordData(res.data)
                    if (!res.data.list.length) {
                        setShowNoData(true)
                    } else {
                        setShowNoData(false)
                    }
                }
            })
        }
        if (props.type === 'poker') {
            getSubordinateBetRecordPoker({ ...params, ...props.betMoneyParams, ...props.conditionParams, type: props.type, games_type: props.gamesType, time_type: props.timeType, award_result: props.awardResult, status:checkedStatusParams, pageSize: 10, page: curPage }).then(res => {
                if (res.result === 200000) {
                    setSubordinateBetRecordData(res.data)
                    if (!res.data.list.length) {
                        setShowNoData(true)
                    } else {
                        setShowNoData(false)
                    }
                }
            })
        }
        if (props.type === 'sports') {
            getSubordinateBetRecordSport({ ...params, ...props.betMoneyParams, ...props.conditionParams, type: props.type, games_type: props.gamesType, time_type: props.timeType, award_result: props.awardResult, status:checkedStatusParams, pageSize: 10, page: curPage }).then(res => {
                if (res.result === 200000) {
                    setSubordinateBetRecordData(res.data)
                    if (!res.data.list.length) {
                        setShowNoData(true)
                    } else {
                        setShowNoData(false)
                    }
                }
            })
        }
        if (props.type === 'lottery') {
            getSubordinateBetRecordLottery({ ...params, ...props.betMoneyParams, ...props.conditionParams, type: props.type, games_type: props.gamesType, time_type: props.timeType, award_result: props.awardResult, status:checkedStatusParams, pageSize: 10, page: curPage }).then(res => {
                if (res.result === 200000) {
                    setSubordinateBetRecordData(res.data)
                    if (!res.data.list.length) {
                        setShowNoData(true)
                    } else {
                        setShowNoData(false)
                    }
                }
            })
        }
    }, [props.subordinateBetRecordParams, curPage]) // eslint-disable-line react-hooks/exhaustive-deps

    // 查看详情
    function handleViewDetail(item) {
        getBetOrderDetail({ bet_order_no: item.bet_order_no, alias: item.alias }).then(res => {
            if (res.result === 200000) {
                setSubordinateBetOrderDetailData(res.data)
                setShowDetailModel(true)
            }
        })
    }
    // 投注来源/方式
    function fromFilters(val) {
        switch (val) {
            case 1:
                return 'PC'
            case 2:
                return 'H5'
            case 3:
                return 'Android'
            case 4:
                return 'IOS'
            default:
                return '-'
        }
    }
    return (
        <div className="subordinate-bet-record-wrap">
            {!showNoData && <Pagination className="subordinate-bet-record-pagination" total={subordinateBetRecordData.pageCount} current={curPage} onChange={v => setCurPage(v)} />}
            {/* 全部平台 */}
            {!showNoData && subordinateBetRecordData.list.map((item, index) => {
                return (
                    <div className="subordinate-bet-record-box" key={index}>
                        <div className="subordinate-bet-record-item">
                            <h3>会员信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">会员账号：</span>
                                <span className="content">{item.username}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">所属代理：</span>
                                <span className="content">{item.agent_name}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">所属总代：</span>
                                <span className="content">{item.top_agent_name}</span>
                            </p>
                        </div>
                        {(props.type === 'video' || props.type === 'poker') && item.detail !== "" &&
                            <div className="subordinate-bet-record-item 1">
                                <h3>投注详情</h3>
                                {props.type === 'video' &&
                                    <p className="report-info-wrap">
                                        <span className="label">场次：</span>
                                        <span className="content">{item.round_no}</span>
                                    </p>
                                }
                                {
                                    typeof (item.detail) === 'string' &&
                                    <p className="report-info-wrap">
                                        <span className="content" dangerouslySetInnerHTML={{ __html: item.detail }}></span>
                                    </p>
                                }
                                {
                                    item.detail && typeof (item.detail) !== 'string' && Object.keys(item.detail).map(key => {
                                        return (
                                            <div className="report-info-wrap" key={key}>
                                                <span className="label">{key}：</span>
                                                {typeof (item.detail[key]) === 'string' && item.detail[key].indexOf('.png') === -1 && <span className="content">{item.detail[key]}</span>}
                                                {typeof (item.detail[key]) === 'string' && item.detail[key].indexOf('.png') > -1 && <img src={require(`@assets/images/${item.detail[key]}`).default} className="string-content-img" alt="" />}
                                                {Array.isArray(item.detail[key]) &&
                                                    <div className="content array-content-wrap">
                                                        {item.detail[key].map((item1, index1) => {
                                                            return (
                                                                <Fragment>
                                                                    {!Array.isArray(item1) && (item1.indexOf('.png') === -1 || item1.indexOf('img') > -1) && <div className="array-content-item" key={index1} dangerouslySetInnerHTML={{ __html: item1 }}></div>}
                                                                    {!Array.isArray(item1) && item1.indexOf('.png') > -1 && item1.indexOf('img') === -1 &&
                                                                        <img src={require(`@assets/images/${item1}`).default} className="array-content-img" alt="" />
                                                                    }
                                                                    {Array.isArray(item1) && item1.map((item2,index2)=>{
                                                                        return (
                                                                            <img key={index2} src={require(`@assets/images/${item2}`).default} className="array-content-img" alt="" />
                                                                        )
                                                                    })}
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                {Object.prototype.toString.call(item.detail[key]) === '[object Object]' &&
                                                    <div className="content">
                                                        {Object.keys(item.detail[key]).map(objKey => {
                                                            return (
                                                                <div className="obj-content-wrap" key={objKey}>
                                                                    <span className="label">{objKey}：</span>
                                                                    {Array.isArray(item.detail[key][objKey])&&item.detail[key][objKey].map((item2, index2) => {
                                                                        return (
                                                                            <div className="obj-content-item" key={index2}>
                                                                                {item2.indexOf('.png') > -1 &&
                                                                                    <img src={require(`@assets/images/${item2}`).default} alt="" />
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {typeof (item.detail[key][objKey]) === 'string' &&
                                                                        <div className="obj-content-item">
                                                                            {item.detail[key][objKey].indexOf('.png') > -1 &&
                                                                                <img src={require(`@assets/images/${item.detail[key][objKey]}`).default} alt="" />
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            props.type === 'egc' &&
                            <Fragment>
                                <div className="subordinate-bet-record-item">
                                    <h3>赛事详情</h3>
                                    <p className="report-info-wrap">
                                        <span className="egc-html" dangerouslySetInnerHTML={{ __html: item.details }}></span>
                                    </p>
                                </div>
                                <div className="subordinate-bet-record-item">
                                    <h3>投注详情</h3>
                                    <p className="report-info-wrap">
                                        <span className="egc-html" dangerouslySetInnerHTML={{ __html: item.way }}></span>
                                    </p>
                                </div>
                            </Fragment>
                        }
                        {
                            props.type === 'sports' &&
                            <Fragment>
                                <div className="subordinate-bet-record-item">
                                    <h3>赛事详情</h3>
                                    <p className="report-info-wrap">
                                        <span className="egc-html" dangerouslySetInnerHTML={{ __html: item.match_info }}></span>
                                    </p>
                                </div>
                                <div className="subordinate-bet-record-item">
                                    <h3>投注详情</h3>
                                    <p className="report-info-wrap">
                                        <span className="egc-html" dangerouslySetInnerHTML={{ __html: item.bet_info }}></span>
                                    </p>
                                </div>
                            </Fragment>
                        }
                        {
                            props.type === 'lottery' &&
                            <Fragment>
                                <div className="subordinate-bet-record-item">
                                    <h3>投注内容</h3>
                                    <p className="report-info-wrap">
                                        <span className="egc-html" dangerouslySetInnerHTML={{ __html: item.bet_content }}></span>
                                    </p>
                                </div>
                                <div className="subordinate-bet-record-item">
                                    <h3>开奖结果</h3>
                                    <p className="report-info-wrap">
                                        <span className="egc-html" dangerouslySetInnerHTML={{ __html: item.numbers }}></span>
                                    </p>
                                </div>
                            </Fragment>
                        }
                        <div className="subordinate-bet-record-item">
                            <h3>投注信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">游戏名称：</span>
                                <span className="content">{item.game_name}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">注单号：</span>
                                <span className="content">{item.bet_order_no}</span>
                            </p>
                            {
                                props.type === 'lottery' &&
                                <p className="report-info-wrap">
                                    <span className="label">期号：</span>
                                    <span className="content">{item.issue}</span>
                                </p>
                            }
                            <p className="report-info-wrap">
                                <span className="label">投注金额：</span>
                                <span className="content">{item.bet_amount}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">有效投注额：</span>
                                <span className="content">{item.valid_bet_amount}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">投注时间：</span>
                                <span className="content">{item.bet_time}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">投注来源：</span>
                                <span className="content">{item.from}</span>
                            </p>
                        </div>
                        <div className="subordinate-bet-record-item">
                            <h3>结算信息</h3>
                            <p className="report-info-wrap">
                                <span className="label">派彩：</span>
                                <span className="content">{item.prize}</span>
                            </p>
                            {
                                props.type === 'lottery' &&
                                <p className="report-info-wrap">
                                    <span className="label">中奖状态：</span>
                                    <span className="content">{item.check_prize_status}</span>
                                </p>
                            }
                            {
                                props.type === 'lottery' &&
                                <p className="report-info-wrap">
                                    <span className="label">撤单状态：</span>
                                    <span className="content">{item.cancel_status}</span>
                                </p>
                            }
                            <p className="report-info-wrap">
                                <span className="label">输赢金额：</span>
                                <span className="content">{item.profit}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">派彩时间：</span>
                                <span className="content">{item.settlement_time}</span>
                            </p>
                            <p className="report-info-wrap">
                                <span className="label">交易状态：</span>
                                <span className="content">{item.settlement_status}</span>
                            </p>
                        </div>
                        <div className="subordinate-bet-record-item">
                            <h3>操作</h3>
                            <div className="view-btn-box">
                                <Button type="warning" className="primary-btn" onClick={() => handleViewDetail(item)}>查看详情</Button>
                            </div>
                        </div>
                    </div>
                )
            })}
            {showNoData && <NoData />}
            <Modal
                wrapClassName='subordinate-detail-model-wrap'
                visible={showDetailModel}
                transparent
                maskClosable={false}
                title="订单详情"
                footer={[{ text: '确定', onPress: () => { setShowDetailModel(false) } }]}
                wrapProps={{ onTouchStart: onWrapTouchStart }}
            >
                {!Object.keys(subordinateBetOrderDetailData).includes('items') &&
                    <div className="detail-model-content">
                        <div className="detail-model-content-item">
                            <span className="label">玩家</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.username}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">注单号</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.bet_order_no}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">游戏供应商</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.related_name}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">游戏种类</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.game_name || subordinateBetOrderDetailData.suport}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">派彩时间</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.settlement_time}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">投注额</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.bet_amount}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">投注时间</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.bet_time}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">交易状态</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.settlement_status}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">派彩</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.prize}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">有效投注</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.valid_bet_amount}</span>
                        </div>
                        {
                            subordinateBetOrderDetailData.detail !=="" && typeof (subordinateBetOrderDetailData.detail) === 'string' &&
                            <div className="detail-model-content-item">
                                <span className="label">投注详情</span>
                                <span className="egc-html" dangerouslySetInnerHTML={{ __html: subordinateBetOrderDetailData.detail }}></span>
                            </div>
                        }
                        {
                            subordinateBetOrderDetailData.detail && typeof (subordinateBetOrderDetailData.detail) !== 'string' &&
                            Object.keys(subordinateBetOrderDetailData.detail).map(key=>{
                                return (
                                    <div className="detail-model-content-item" key={key}>
                                        <span className="label">{key}</span>
                                        {typeof (subordinateBetOrderDetailData.detail[key]) === 'string' && subordinateBetOrderDetailData.detail[key].indexOf('.png') === -1 && <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.detail[key]}</span>}
                                        {typeof (subordinateBetOrderDetailData.detail[key]) === 'string' && subordinateBetOrderDetailData.detail[key].indexOf('.png') > -1 && <img src={require(`@assets/images/${subordinateBetOrderDetailData.detail[key]}`).default} className="detail-model-string-content-img" alt="" />}
                                        {Array.isArray(subordinateBetOrderDetailData.detail[key]) &&
                                            <div className="detail-model-content-item-content-array">
                                                {subordinateBetOrderDetailData.detail[key].map((item1, index1) => {
                                                    return (
                                                        <Fragment>
                                                            {!Array.isArray(item1) && (item1.indexOf('.png') === -1 || item1.indexOf('img') > -1) &&<div className="array-content-item" key={index1} dangerouslySetInnerHTML={{ __html: item1 }}></div>}
                                                            {!Array.isArray(item1) && item1.indexOf('.png') > -1 && item1.indexOf('img') === -1 &&
                                                                <img src={require(`@assets/images/${item1}`).default} className="detail-model-array-content-img" alt="" />
                                                            }
                                                            {Array.isArray(item1) && item1.map((item2,index2)=>{
                                                                return (
                                                                    <img key={index2} src={require(`@assets/images/${item2}`).default} className="detail-model-array-content-img" alt="" />
                                                                )
                                                            })}
                                                        </Fragment>
                                                    )
                                                })}
                                            </div>
                                        }
                                        {Object.prototype.toString.call(subordinateBetOrderDetailData.detail[key]) === '[object Object]' &&
                                            <div className="detail-model-content-item-content">
                                                {Object.keys(subordinateBetOrderDetailData.detail[key]).map(objKey => {
                                                    return (
                                                        <div className="detail-model-content-item-content-obj" key={objKey}>
                                                            <span>{objKey}：</span>
                                                            {Array.isArray(subordinateBetOrderDetailData.detail[key][objKey])&&subordinateBetOrderDetailData.detail[key][objKey].map((item2, index2) => {
                                                                return (
                                                                    <div className="obj-content-item" key={index2}>
                                                                        {item2.indexOf('.png') > -1 &&
                                                                            <img src={require(`@assets/images/${item2}`).default} alt="" />
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                            {typeof (subordinateBetOrderDetailData.detail[key][objKey]) === 'string' &&
                                                                <div className="obj-content-item">
                                                                    {subordinateBetOrderDetailData.detail[key][objKey].indexOf('.png') > -1 &&
                                                                        <img src={require(`@assets/images/${subordinateBetOrderDetailData.detail[key][objKey]}`).default} alt="" />
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        {Object.keys(subordinateBetOrderDetailData).includes('match_info') &&
                            <Fragment>
                                <div className="detail-model-content-item">
                                    <span className="label">投注详情</span>
                                    <span className="detail-model-content-item-detail" dangerouslySetInnerHTML={{__html:subordinateBetOrderDetailData.bet_info}}></span>
                                </div>
                                <div className="detail-model-content-item">
                                    <span className="label">赛事信息</span>
                                    <span className="detail-model-content-item-detail" dangerouslySetInnerHTML={{__html:subordinateBetOrderDetailData.match_info}}></span>
                                </div>
                            </Fragment>
                        }
                    </div>
                }
                {Object.keys(subordinateBetOrderDetailData).includes('items') &&
                    <div className="detail-model-content">
                        <div className="detail-model-content-item">
                            <span className="label">玩家</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.username}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">注单号</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.bet_order_no}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">游戏名称</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.list[0].game_name}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">投注返点</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.list[0].rakeback_per}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">投注时间</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.list[0].bet_time}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">投注金额</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.list[0].bet_amount}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">中奖金额</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.list[0].profit}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">投注IP</span>
                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.list[0].ip}</span>
                        </div>
                        <div className="detail-model-content-item">
                            <span className="label">投注方式</span>
                            <span className="detail-model-content-item-detail">{fromFilters(subordinateBetOrderDetailData.list[0].from)}</span>
                        </div>
                        {
                            subordinateBetOrderDetailData.items.map((item,index)=>{
                                return (
                                    <Fragment key={index}>
                                        <div className="detail-model-content-title">
                                            订单明细
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">投注期号</span>
                                            <span className="detail-model-content-item-detail">{subordinateBetOrderDetailData.issue}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">开奖结果</span>
                                            <span className="detail-model-content-item-detail">{item.numbers}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">投注玩法</span>
                                            <span className="detail-model-content-item-detail">{item.play_name}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">投注号码</span>
                                            <span className="detail-model-content-item-detail" dangerouslySetInnerHTML={{__html:item.code}}></span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">号码赔率</span>
                                            <span className="detail-model-content-item-detail">{item.odds}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">单注倍数</span>
                                            <span className="detail-model-content-item-detail">{item.multiple}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">总注数</span>
                                            <span className="detail-model-content-item-detail">{item.single_num}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">单注金额</span>
                                            <span className="detail-model-content-item-detail">{item.money}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">本方案金额</span>
                                            <span className="detail-model-content-item-detail">{item.amount}</span>
                                        </div>
                                        <div className="detail-model-content-item">
                                            <span className="label">中奖金额</span>
                                            <span className="detail-model-content-item-detail">{item.profit}</span>
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                        <div className="detail-model-content-introduce">
                            <p>温馨小提示：</p>
                            <p>1、方案金额 = 当前倍数 * 单倍注数 * 单注金额</p>
                            <p>2、中奖金额 = 中奖的号码1(中奖号码的赔率 * 单注金额 * 单倍注数) + 中奖的号码2(中奖号码的赔率 * 单注倍数 * 单注金额)</p>
                            <p>3、如果是角模式需要除于10换算成元计算</p>
                        </div>
                    </div>
                }
            </Modal>
        </div>
    )
}
//最后要配合forwardRef
const SubordinateRebateRecord = forwardRef(Index);
export default SubordinateRebateRecord;