import React from 'react'
import './index.css'
export default function NoData() {
    return (
        <div className="no-data">
            <img src={require('./images/empty_state.png').default} alt="" />
            <span>暂无数据</span>
        </div>
    )
}
