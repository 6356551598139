import React from 'react'
import './index.css'
import {copyAccess} from '@utils/copy'
import { useSelector } from 'react-redux'

export default function MemberRecommend() {
    const agentInfo = useSelector(state => JSON.parse(state.agentReducer.agentInfo))
    return (
        <div className="member-recommend-wrap">
            <div>
                <span className="label">下线会员：</span>
                <strong>{(agentInfo&&agentInfo.subordinateNum) || 0}</strong>
            </div>
            <div>
                <span className="label">在线人数：</span>
                <strong>{(agentInfo&&agentInfo.subordinateOnlineNum) || 0}</strong>
            </div>
            <div className="recommend-box">
                <span className="label">推荐码：</span>
                <strong id="recommend-text">{(agentInfo&&agentInfo.recommend)}</strong>
                <img src={require('./images/copy.png').default} alt="" onClick={()=>copyAccess('#recommend-text')} />
            </div>
        </div>
    )
}
