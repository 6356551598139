import React from 'react'
import './index.css'
import {Link} from 'react-router-dom'

export default function Footer(props) {
    const pathname = props.location.pathname
    return (
        <div className="footer-wrap">
          <Link to="/" className="footer-item">
            {
              pathname === '/' ? <img src={require('./images/Chart_fill.png').default} alt="" /> : <img src={require('./images/Chart.png').default} alt="" />
            }
            <span className={pathname === '/' ?'foot-active':''}>代理报表</span>
          </Link>
          <Link to="/rechargeWithDrawRecord" className="footer-item">
            {
              pathname === '/rechargeWithDrawRecord' ? <img src={require('./images/Wallet_alt_fill.png').default} alt="" /> : <img src={require('./images/Wallet_alt.png').default} alt="" />
            }
            <span className={pathname === '/rechargeWithDrawRecord' ?'foot-active':''}>充提记录</span>
          </Link>
          <Link to="/downloadList" className="footer-item">
            {
              pathname === '/downloadList' ? <img src={require('./images/Chart_fill.png').default} alt="" /> : <img src={require('./images/Chart.png').default} alt="" />
            }
            <span className={pathname === '/downloadList' ?'foot-active':''}>下载列表</span>
          </Link>
          <Link to="/bettingRebate" className="footer-item">
            {
              pathname === '/bettingRebate' ? <img src={require('./images/Form_fill.png').default} alt="" /> : <img src={require('./images/Form.png').default} alt="" />
            }
            <span className={pathname === '/bettingRebate' ?'foot-active':''}>投注返佣</span>
          </Link>
          <Link to="/member" className="footer-item">
            {
              pathname === '/member' ? <img src={require('./images/User_fill.png').default} alt="" /> : <img src={require('./images/User.png').default} alt="" />
            }
            <span className={pathname === '/member' ?'foot-active':''}>下线会员</span>
          </Link>
        </div>
    )
}
