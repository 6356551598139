import {Toast} from 'antd-mobile'
//一键复制功能
export function copyAccess (dom) {
    //因为我的input框里面还有button 按钮，所以在选择节点的时候，一定要只选择input  
    var copyDOM = document.querySelector(dom);  //需要复制文字的节点
    var range = document.createRange(); //创建一个range
    window.getSelection().removeAllRanges();   //清楚页面中已有的selection
    range.selectNode(copyDOM);    // 选中需要复制的节点    
    window.getSelection().addRange(range);   // 执行选中元素
    var successful = document.execCommand('copy');    // 执行 copy 操作  
    if(successful){
        Toast.success('复制成功！')
    }else{
        Toast.warning('复制失败，请手动复制！')
    }
    // 移除选中的元素  
    window.getSelection().removeAllRanges(); 
}